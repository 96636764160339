<template>
  <div class="my-commend p20 bsbb">
    <van-nav-bar title="我的评价" left-arrow @click-left="onClickLeft" />
    <div class="write df aic jcsb fz28 p20 bsbb" style="color: green" @click="goWrite">
      <div class="df aic">
        <van-icon name="records" class="mr20" />
        <p>我的评价</p>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="my-all">
      <!-- <div class="shop-item mb30 df aic bsbb">
                          <img src="../../assets/images/xg/recommend1.jpg" />
                          <div class="df fdc">
                            <div class="fz28 fw7 mb10">菜大全精品瓜....</div>
                            <p class="green fw4 fz18 mb20">双流区热评商家</p>
                            <div class="green fw4 fz14">
                              <span>40减2</span>
                              <span>新客减2</span>
                            </div>
                          </div>
                        </div> -->
      <div class="orderItem mb30 df fdc p30 bsbb" v-for="(v, i) in unfinishedOrder" :key="i">
        <p class="fz28">订单编号 : {{ v.order_no }}</p>
        <p class="fz28">实际购买价格 : {{ v.buy_actual_amount }}</p>
        <p class="fz28" v-if="v.payment_method = 10">支付方式 : 微信支付</p>
        <p class="fz28" v-else>支付方式 : 支付宝支付</p>
        <div class="df aic jcsa">
          <van-button type="success" style="width: 100px;height:30px;border-radius: 20px;margin-top:10px"
            @click="goCommend(v.id)">去评价</van-button>
          <van-button type="danger" style="width: 100px;height:30px;border-radius: 20px;margin-top:10px"
            @click="deleteItem(v.id)">删除订单</van-button>
        </div>
      </div>
      <!-- <div class=" item">
                        <van-swipe-cell>
                          <van-card desc="双流区热评商家" title="菜大全精品瓜..." class="goods-card"
                            thumb="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg" />
                          <template #right>
                            <van-button square text="删除" type="danger" class="delete-button" @click="deleteItem" />
                            <van-button square type="success" text="置顶" />
                          </template>
                        </van-swipe-cell>
                        <van-button type="success" text="去评价" @click="goCommend(1)" />
                      </div>
                      <div class="item">
                        <van-swipe-cell>
                          <van-card desc="双流区热评商家" title="菜大全精品瓜..." class="goods-card"
                            thumb="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg" />
                          <template #right>
                            <van-button square text="删除" type="danger" class="delete-button" @click="deleteItem" />
                            <van-button square type="success" text="置顶" />
                          </template>
                        </van-swipe-cell>
                        <van-button type="success" text="去评价" />
                      </div>
                      <div class="item">
                        <van-swipe-cell>
                          <van-card desc="双流区热评商家" title="菜大全精品瓜..." class="goods-card"
                            thumb="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg" />
                          <template #right>
                            <van-button square text="删除" type="danger" class="delete-button" @click="deleteItem" />
                            <van-button square type="success" text="置顶" />
                          </template>
                        </van-swipe-cell>
                        <van-button type="success" text="去评价" />
                      </div> -->
    </div>
  </div>
</template>

<script>
import { getAllMyEvaluate, deleteComment, getMyOrderReq } from "@/utils/api.js";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      orderList: [],
      unfinishedOrder: [],
      payWay: ''
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    goWrite() {
      this.$router.push("/user/showAllMycommend");
    },
    deleteItem(n) {
      Dialog.confirm({
        title: "确定删除吗",
      })
        .then(() => {
          deleteComment({ id: n }).then((res) => {
            if (res.code === 200) {
              Toast.success(res.message)
            }
          });
        })
        .catch(() => { });
    },
    goCommend(n) {
      this.$router.push({
        name: "evaluateContent",
        query: {
          id: n,
        },
      });
    },
    orderWay() {
      if (this.unfinishedOrder.forEach(v => v.payment_method == 10)) return this.payWay = '微信支付'
      if (this.unfinishedOrder.forEach(v => v.payment_method == 20)) return this.payWay = '支付宝支付'
    }
  },
  mounted() {
    getAllMyEvaluate({ page: 1, page_size: 20 }).then((res) => {
      console.log(111);
    });
    getMyOrderReq({ page: 1, page_size: 50 }).then((res) => {
      this.orderList = res.data.list;
      this.unfinishedOrder = this.orderList.filter(v => v.payment_status == 50)
      console.log(res.data.list, this.unfinishedOrder);
    });
  },
  created() {
    this.orderWay();
  },
};
</script>

<style lang="scss" scoped>
.van-card {
  width: 690px;
  height: 200px;
  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 20px;
}

::v-deep .van-image__img {
  width: 230px;
  height: 140px;
}

::v-deep .van-card__title {
  padding: 10px;
  font-size: 28px;
  font-weight: 700;
}

::v-deep .van-card__content {
  margin-left: 150px;
}

::v-deep .van-card__desc {
  font-size: 20px;
  font-weight: 400;
  padding: 15px;
  color: green;
}

.goods-card {
  margin: 0;
  background-color: #fff;
}

.delete-button,
.van-button--square {
  height: 100%;
  line-height: 40px;
}

.my-commend {
  font-family: "siyuan";

  .write {
    width: 690px;
    height: 105px;
    background: #eff9f0;
    border-radius: 40px;
    margin: 40px auto;
  }

  .my-all {
    .item {
      position: relative;
      margin-bottom: 20px;

      >.van-button {
        position: absolute;
        right: 50px;
        top: 120px;
        border-radius: 20px;
      }
    }

    .orderItem {
      width: 690px;
      border-radius: 20px;
      background-color: #fff;
    }

    .shop-item {
      width: 690px;
      height: 200px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(240, 240, 240, 1);
      padding: 30px 20px;

      img {
        width: 220px;
        height: 141px;
        margin-right: 20px;
      }

      .green {
        color: rgba(90, 194, 104, 1);
      }

      span {
        border: 1px solid rgba(90, 194, 104, 1);
        margin-right: 20px;
        border-radius: 12px;
        display: inline-block;
        box-sizing: border-box;
        padding: 5px 7.5px;
      }
    }
  }
}
</style>